import React, { useState} from 'react';
import PropTypes from 'prop-types';

const Request = ({request, handleRequestChange}) => {
	//const [requestType, setRequestType] = useState('');

	const handleButtonClick = (value) => {
		//setRequestType(value);
		handleRequestChange({ target: { id: 'request-type', value } });
	};

	return (
		<div className="form-section">
			<div className="c-2 form-row center">
				<button	
					type='button'
					className={request['request-type'] === 'equipment-purchase' ? 'btn wide mx-a' : 'btn inverted wide mx-a'}
					value={request['request-type']} 
					onClick={(event) => {
						event.preventDefault();
						//setRequestType('equipment-purchase');
						handleButtonClick('equipment-purchase');}
					}
				>
					Equipment Purchase/Lease
				</button>
				<button 
					type='button'
					className={request['request-type'] === 'business-loan' ? 'btn wide mx-a' : 'btn inverted wide mx-a'} 
					value={request['request-type']} 
					onClick={(event) => {
						event.preventDefault();
						//setRequestType('business-loan');
						handleButtonClick('business-loan');}
					}
				>
					Business Loan
				</button>
				<button 
					type='button'					
					className={request['request-type'] === 'refinance' ? 'btn wide mx-a' : 'btn inverted wide mx-a'} 
					value={request['request-type']} 
					onClick={(event) => {
						event.preventDefault();
						//setRequestType('refinance');
						handleButtonClick('refinance');}
					}
				>
					Refinance
				</button>
				<button 
					type='button'
					className={request['request-type'] === 'other' ? 'btn wide mx-a' : 'btn inverted wide mx-a'} 
					value={request['request-type']} 
					onClick={(event) => {
						event.preventDefault();
						//setRequestType('other');
						handleButtonClick('other');}
					}
				>
					Other
				</button>
			</div>
			<div className="form-row c-2">
				<label className="w-50" htmlFor="amount">Amount</label>
				<input 
					className="w-50"
					type="number"
					id="amount"
					value={request['amount']}
					onChange={handleRequestChange}
					required
				/>
			</div>
			{ request['request-type'] === 'equipment-purchase' ? (
				<div className="form-row c-2">
					<label 
						className="w-50 no-spinner" 
						htmlFor="equipment">Equipment
					</label>
					<textarea 
						id="equipment"
						value={request['equipment']}
						onChange={handleRequestChange}
						required
					/>
				</div>
			) : null }
		</div>
	);
};

Request.propTypes = {
	request: PropTypes.object.isRequired,
	handleRequestChange: PropTypes.func.isRequired
};

export default Request;